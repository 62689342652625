import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const IndexPage = () => (
  <Layout>
    <SEO fullTitle="Codestack BV" />

    <Hero title="Hi," subtitle="We are Codestack. Nice to meet you." />

    <section className="section main">
      <div className="columns">
        <div className="column">
          <div className="column-content">
            <h2 className="accent title is-4">Who we are</h2>
            <p>
              Founded in 2017, Codestack is a dynamic software development
              company that strives to deliver{" "}
              <strong>quality software solutions</strong>. Built upon years of
              experience with a variety of clients, we are committed to offering
              robust solutions through a human approach.
            </p>
          </div>
        </div>
        <div className="column">
          <div className="column-content">
            <h2 className="accent title is-4">What we do</h2>
            <p>
              We build software. Whether you are a startup laying down the
              foundations or an established software company who needs extra
              manpower to get that new feature shipped, Codestack will assist
              you with <strong>professional consultancy</strong> advice and
              solutions.
            </p>
          </div>
        </div>
        <div className="column">
          <div className="column-content">
            <h2 className="accent title is-4">How we do it</h2>
            <p>
              We believe in a honest, <strong>no-nonsense</strong> approach to
              come up with solutions tailored to your needs. We keep a close eye
              on the latest trends in the industry without ever losing sight of
              the main goal: using the right tools to solve the right problems.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div className="content has-text-centered">
      <div className="container">
        <h2 className="accent title is-4">Let's work together</h2>
        <div>
          <p>
            Send us a message at{" "}
            <a href="mailto:info@codestack.be">info@codestack.be</a>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
